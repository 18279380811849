import React, { useState, useCallback, useEffect, Fragment } from "react";

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import 'bootstrap/dist/css/bootstrap.css';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { Alert } from 'reactstrap';

import LoadingOverlay from 'react-loading-overlay'
import NavigationBar from './NavigationBar';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from "react-i18next";

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const { Option } = Select;
const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

main {
	min-height: 50vh;
	padding-bottom: 90px;
}

footer{
	height: 80px;
	margin-top: -80px;
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const mutation = gql`
    mutation ContactUs($subject: String, $name: String, $email: String, $message: String) {
        contactUs(subject:$subject, name: $name, email:$email, message:$message)
}
`;
const { TextArea } = Input;
const ContactUs = (props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [paymentStatus, setPaymentStatus] = useState({message:''})
    const [showSpinner, setShowSpinner] = useState({display: false})
    const [showTracker, setShowTracker] = useState(false)
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [insertItem] = useMutation(mutation);
    const onFinish = values => {
        //console.log('Success:', values);
        setShowSpinner({display: true});
        insertItem({
            variables: {
                subject: values.subject, 
                name: values.fullname, 
                email: values.email, 
                message: values.message, 
            }
            }).then(
            res => {
                if (res) {
                    //console.log('res:',res);
                    form.resetFields();
                    setshowSuccessMessage(true);
                    setShowSpinner({display: false});
                }
            },
            err => {console.log(err); }
            );
        
    };

    return (
        <Fragment>
            <LoadingOverlay
                active={showSpinner.display}
                spinner
                text={t('loading_content')}
                >
                <NavigationBar />
                <Header 
                    showTracker={showTracker}
                />
                <Styles>
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <h2>{t('e_cus_service')}</h2>
                                    <div className="divider-orange my-4"></div>
                                    {/*<p>{t('contact_by_phone')}: 833-771-0764</p>*/}
                                </div>
                                
                            </div>
                        </div>
                        <div className="container">
	                        <div className="row justify-content-center">
                                <div className="col">
                                <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                                    {t('request_has_been_logged')}
                                </Alert>
                                <Form
                                    form={form}
                                    {...layout}
                                    onFinish={onFinish}
                                    >
                                    <Form.Item
                                        name="subject"
                                        rules={[{ required: true, message: t('subject') }]}
                                    >
                                        <Select placeholder={t('subject_placeholder')}>
                                            <Option value='SunPower Payment Inquiry'>{t('sunpower_payment_inquiry')}</Option>
                                            <Option value='My Payment Status'>{t('my_payment_status')}</Option>
                                            <Option value='My Visa Payment Reward Card'>{t('visa_payment_reward_card')}</Option>
                                            <Option value='My SunPower Check'>{t('my_sunpower_check')}</Option>
                                            <Option value='My Virtual Visa Payment'>{t('virtual_visa_payment')}</Option>
                                            <Option value='Update Information for my Payment'>{t('update_info_payment')}</Option>
                                            <Option value='SunPower Payment Email not Received'>{t('payment_email_not_received')}</Option>
                                            <Option value='SunPower Payment Expired'>{t('payment_expired')}</Option>
                                            <Option value='Payment Is Incorrect Amount'>{t('payment_incorrect_amt')}</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="fullname"
                                        rules={[{ required: true, message: t('enter_your_full_name') }]}
                                    >
                                        <Input placeholder={t('track_name')} />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: t('enter_your_email') , type: 'email'}]}
                                        type= "email"
                                        
                                    >
                                        <Input placeholder={t('email')} />
                                    </Form.Item>
                                    <Form.Item
                                        name="message"
                                        rules={[{ required: true, message: t('enter_some_message') }]}
                                    >
                                        <TextArea placeholder={t('message')} rows={5} />
                                    </Form.Item>


                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                        {t('submit')}
                                        </Button>
                                    </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </main>
                </Styles>
                <Footer />
            </LoadingOverlay>
        </Fragment>
        
    );
}

export default ContactUs;